.video-text,
:--layout-dark .video-text,
:--group-dark .video-text {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .video-text,
:--group-light .video-text {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .video-text {
  margin-top: var(--section-gap);
}

.video-text {
  margin-top: var(--section-gap);
}

.video-text__wrap {
  display: grid;
  grid-template-columns: 40fr 60fr;
  gap: 4%;
}

.video-text__wrap--reverse {
  display: grid;
  justify-items: start;
  grid-template-columns: 60fr 40fr;
  gap: 7%;
}

.video-text__wrap__text {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 32px;
  width: 100%;
  height: 100%;
  order: 0;
}

.video-text__wrap--reverse .video-text__wrap__text {
  order: 1;
}

@media (--mobile) {
  .video-text__wrap--reverse .video-text__wrap__text {
    order: 0;
  }
}

.video-text__wrap__text__title {
  font-size: var(--heading-font-size);
  font-size: 26px;

  font-weight: 600;
  line-height: 130%;
  color: var(--color);
}

.video-text__wrap__text hr {
  height: 1px;

  border: 0;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.359),
    rgba(0, 0, 0, 0)
  );
}

.video-text__wrap__text__description {
  font-size: 20px;

  font-weight: 500;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: rgba(var(--color-rgb), 0.5);
}

.video-text__wrap__video {
  display: grid;
  overflow: hidden;
  gap: 12px;
  width: 100%;
  max-width: 540px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;

  border-radius: 16px;

  order: 1;
}

.video-text__wrap--reverse .video-text__wrap__video {
  display: grid;
  gap: 0;

  width: 100%;
  height: 100%;
  margin-left: 0;
  order: 0;
}

@media (--mobile) {
  .video-text__wrap--reverse .video-text__wrap__video {
    margin-left: auto;
    order: 1;
  }
}

.video-text__wrap__cta {
  display: flex;
  justify-content: center;
}
* + .video-text__wrap__cta {
  margin-top: 64px;
}
@media (--mobile) {
  * + .video-text__wrap__cta {
    margin-top: 32px;
  }
}

.video-text__wrap__text__description b {
  font-size: 20px;

  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--primary-500);
}

.video-text__wrap__text__description p {
  font-size: 20px;

  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.02em;
}

@media (--tablet) {
  .video-text__wrap__text__before {
    display: none;
  }

  .video-text__wrap__text {
    gap: 16px;
  }

  .video-text__wrap__text__title {
    margin-top: 12px;

    font-size: 24px;
  }

  .video-text__wrap__text__description {
    font-size: 16px;
  }
}

@media (--mobile) {
  .video-text {
    margin-top: 64px;
  }
  .video-text__wrap {
    grid-template-columns: 1fr;
    gap: 3%;
  }

  .video-text__wrap__text__title {
    font-size: 24px;
    text-align: center;
  }

  .video-text__wrap__text__description {
    text-align: center;
  }
}
