.video:first-child,
* + .video {
  margin-top: var(--section-gap);
}

.video__container {
  flex: 1;
  width: 100%;
  max-width: 820px;
  margin-right: auto;
  margin-left: auto;
}
@media (--tablet) {
  .video__container {
    max-width: 800px;
  }
}

.video__wrap {
  position: relative;

  overflow: hidden;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  border-radius: 16px;
  background-color: var(--dark);
}
@media (--mobile) {
  .video__wrap {
    width: auto;
  }
}

.video__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.video__overlay__button {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
}

.video__overlay__button span {
  --size: 112px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  border-radius: 999px;
  background-color: rgba(5, 2, 2, 0.5);
  backdrop-filter: blur(10px);
  transition:
    200ms background-color,
    200ms opacity;
}
.video__overlay__button:hover span {
  background-color: rgba(5, 2, 2, 0.3);
}
.video__overlay__button:active span {
  background-color: rgba(5, 2, 2, 0.5);
}
.video--playing .video__overlay__button span {
  opacity: 0;
}
@media (--mobile) {
  .video__overlay__button span {
    --size: 80px;
  }
}

.video__overlay__button img {
  --size: 42px;

  width: var(--size);
  height: var(--size);
}
@media (--mobile) {
  .video__overlay__button img {
    --size: 32px;
  }
}

.video__video {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}
