.waiting-list-form,
:--layout-dark .waiting-list-form,
:--group-dark .waiting-list-form {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --color-rgba: rgba(var(--white-rgb), 1);
}
:--layout-light .waiting-list-form,
:--group-light .waiting-list-form {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --color-rgba: rgba(var(--black-rgb), 0.5);
}

.landing-testimonials + .waiting-list-form {
  margin-top: var(--landing-testimonials-gap);
}

* + .waiting-list-form {
  margin-top: 64px;
}

.waiting-list-form__form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;

  border: 1px solid rgba(255, 255, 255, 0);

  border-radius: 16px;

  background: linear-gradient(
    276deg,
    rgba(255, 247, 247, 0.214) 33.43%,
    rgba(178, 178, 178, 0.162) 98.78%
  );
}

@media (--mobile) {
  .waiting-list-form__form {
    flex-direction: column;
    padding: 16px;
  }
}

.group--theme-white .waiting-list-form__form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;

  padding-bottom: 32px;

  border: 1px solid rgba(251, 112, 107, 0.42);
  border-radius: 16px;
  background: linear-gradient(
    276deg,
    rgba(246, 221, 220, 0.48) 33.43%,
    rgba(246, 221, 220, 0.24) 98.78%
  );
}

.group--theme-grey-100 .waiting-list-form__form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;

  padding-bottom: 32px;

  border: 1px solid rgba(251, 112, 107, 0.42);
  border-radius: 16px;
  background: linear-gradient(
    276deg,
    rgba(246, 221, 220, 0.48) 33.43%,
    rgba(246, 221, 220, 0.24) 98.78%
  );
}

.waiting-list-form__form__title {
  max-width: 400px;

  font-size: 24px;

  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--color);
}

@media (--mobile) {
  .waiting-list-form__form__title {
    text-align: center;
  }
}

.waiting-list-form__form__title strong {
  max-width: 400px;

  font-size: 24px;

  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: var(--primary-500);
}
